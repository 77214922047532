import React from "react";
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo";
import {NotificationContainer} from 'react-notifications';
import UnsubscribeEmail from '../components/unsubscribe/UnsubscribeEmail';

const Unsubscribe = () => (
    <Layout mode={true} sticky_footer={true}>
    <SEOComponent title="Amber by inFeedo | Unsubscribe" />
        <UnsubscribeEmail/>
        <NotificationContainer/>
  </Layout>
)

export default Unsubscribe